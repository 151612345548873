<script>
import i18n from "../i18n";

export default {
  data() {
    return {
      admin_name: this.admin_name,
      username: "",
      role: this.role,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French"
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish"
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese"
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic"
        }
      ],
      current_language: "en"
    };
  },
  // components: { simplebar },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('admin_name');
      localStorage.removeItem('admin_id');
      this.$router.push({ name: 'login' });
    },
    profile() {
      this.$router.push({ name: 'profile' });
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },
    getRole() {
      let role = localStorage.getItem('role');
      if (role == 'superadmin') {
        this.$router.push({ name: "dashboard" });
      }
      else {
        this.$router.push({ name: "profile" });
      }
    }
  },
  mounted() {
    this.admin_name = localStorage.getItem('admin_name');
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <div @click="getRole" class="logo logo-dark pointerLogo">
            <span class="logo-sm">
              <img src="/favicon.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.svg" alt height="20" />
            </span>
          </div>

          <!-- <template v-if=""> -->
          <div @click="getRole" class="logo logo-light pointerLogo">
            <span class="logo-sm">
              <img src="/favicon.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.svg" alt height="50" />
            </span>
          </div>
          <!-- </template> -->
        </div>

        <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn">
          <i class="ri-menu-2-line align-middle"></i>
        </button>
      </div>

      <div class="d-flex">
        <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
          <template v-slot:button-content>
            <img class="rounded-circle header-profile-user" src="@/assets/images/user-icon.png" alt="Header Avatar" />
            <span class="d-none d-xl-inline-block ml-1">{{admin_name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <a class="dropdown-item text-primary" style="cursor: pointer;" @click.prevent="profile">
            <i class="ri-edit-line align-middle mr-1 text-primary"></i>
            {{ $t('navbar.dropdown.kevin.list.profile') }}
          </a>
          <a class="dropdown-item text-danger" style="cursor: pointer;" @click.prevent="logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t('navbar.dropdown.kevin.list.logout') }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}

.pointerLogo {
  cursor: pointer;
}
</style>